<template>
    <div class="wrap">
        <el-row>
            <el-col style="width: 78%">
                <div v-show="checkedMap == '高德'" id="container" tabindex="0"></div>
                <baidu-map v-show="checkedMap == '百度'" v-loading="mapLoading" :center="center" :zoom="zoom" id="container" @ready="handler" :max-zoom="16" :mapClick="false" :scroll-wheel-zoom="true">
                    <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                    <bm-marker :position="startPoint" :icon="startIcon"></bm-marker>
                    <bm-marker :position="endPoint" :icon="endIcon"></bm-marker>
                    <bm-circle v-if="GDmapCircleVisible" :center="circlePath.center" :radius="circlePath.radius" stroke-color="#F33" fill-color="#ee2200" :fill-opacity="0.35" :stroke-weight="3" @lineupdate="updateCirclePath" :editing="circlePath.editing"></bm-circle>
                    <!-- 三急一超结束 -->
                    <bm-polyline :path="polylinePath" stroke-color="#0766FE" :stroke-weight="5" :strokeOpacity="1"></bm-polyline>
                </baidu-map>
                <div class="select_map">
                    <el-select v-model="checkedMap" @change="initGPSData" size="mini" style="width: 100px; margin-right: 0.3rem" v-if="0">
                        <el-option label="百度" value="百度"></el-option>
                        <!-- <el-option label="高德" value="高德"></el-option> -->
                    </el-select>
                    <el-radio-group v-if="GDmapCircleVisible" v-model="AMapCircleRadio" size="mini">
                        <el-radio-button label="打开编辑电子围栏" @click.native="openEditCircle"></el-radio-button>
                        <el-radio-button label="关闭编辑电子围栏" @click.native="closeEditCircle"></el-radio-button>
                    </el-radio-group>
                </div>
                <div class="vehicle_info_box">
                    <div class="vehicle_info vehicle_info1">
                        <p class="info_title">车辆信息</p>
                        <div class="flex">
                            <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon01.png" /></div>
                            <span>设备号：</span>
                            <span>{{ checkedRoute.deviceNumber || '--' }}</span>
                        </div>
                        <div class="flex">
                            <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon02.png" /></div>
                            <span>车牌号：</span>
                            <span>{{ checkedRoute.platNo || '--' }}</span>
                        </div>
                    </div>
                    <div class="vehicle_info vehicle_info2">
                        <p class="info_title">行程分数</p>
                        <div class="progress_box">
                            <el-progress :width="progressWidth" :format="format" color="#09A9C5" type="circle" :stroke-width="7" :percentage="checkedRoute.commercialVehicleScore"></el-progress>
                        </div>
                    </div>
                    <div class="vehicle_info vehicle_info3">
                        <p class="info_title">三急一超</p>
                        <ul>
                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon03.png" /></div>
                                <p>{{ Math.ceil(checkedRoute.overSpeedAlarmNum / 1) || '0' }}</p>
                                <div>超速</div>
                            </li>
                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon04.png" /></div>
                                <p>{{ Math.ceil(checkedRoute.sharpSpeedUpAlarmNum / 1) || '0' }}</p>
                                <div>急加速</div>
                            </li>
                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon05.png" /></div>
                                <p>{{ Math.ceil(checkedRoute.sharpSpeedDownAlarmNum / 1) || '0' }}</p>
                                <div>急减速</div>
                            </li>

                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon06.png" /></div>
                                <p>{{ Math.ceil(checkedRoute.sharpTurnAlarmNum / 1) || '0' }}</p>
                                <div>急转弯</div>
                            </li>
                        </ul>
                    </div>
                    <div class="vehicle_info vehicle_info4">
                        <p class="info_title">行程数据</p>
                        <ul>
                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon07.png" /></div>
                                <div>
                                    <b>{{ checkedRoute.mileage || '--' }}</b>
                                    <span>km</span>
                                </div>
                                <p>行驶里程</p>
                            </li>
                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon08.png" /></div>
                                <div>
                                    <b>{{ checkedRoute.drivingDurationHours || '--' }}</b>
                                    <span>h</span>
                                </div>
                                <p>行驶时长</p>
                            </li>
                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon09.png" /></div>
                                <div>
                                    <b>{{ checkedRoute.avgSpeedPerHour || '--' }}</b>
                                    <span>km/h</span>
                                </div>
                                <p>平均速度</p>
                            </li>
                            <li>
                                <div class="icon"><img src="../../../public/images/msi/vehicle_map/icon10.png" /></div>
                                <div>
                                    <b>{{ checkedRoute.maxSpeed || '--' }}</b>
                                    <span>km/h</span>
                                </div>
                                <p>最大速度</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </el-col>
            <el-col style="width: 22%">
                <div class="route_list_box" @scroll="routeScroll">
                    <div class="total flex">
                        <div class="flex">
                            <div>今日里程</div>
                            <b>{{ nowStatistics.totalMileage }}</b>
                            <span>km</span>
                        </div>
                        <div class="flex">
                            <div>今日时长</div>
                            <b>{{ nowStatistics.totalDuration }}</b>
                            <span>h</span>
                        </div>
                    </div>
                    <div class="now_route">
                        <div class="route_title">当前行程</div>
                        <div v-if="!checkedRoute.startTime" v-loading="tableLoading" style="height: 100px"></div>
                        <template v-if="checkedRoute.startTime">
                            <div class="date">
                                <b>{{ checkedRoute.startTime.split(' ')[0] }}</b>
                                <p>{{ checkedRoute.startTime.split(' ')[1] + ' - ' + checkedRoute.endTime.split(' ')[1] }}</p>
                            </div>
                            <div class="start_addr flex">
                                <span>{{ checkedRoute.startAddress }}</span>
                            </div>
                            <div class="end_addr flex">
                                <span>{{ checkedRoute.endAddress }}</span>
                            </div>
                        </template>
                        <p v-else-if="!tableLoading" class="null_data_txt">暂无数据</p>
                    </div>
                    <div class="route_list">
                        <div class="route_title">历史行程列表</div>
                        <ul :class="tableData.length > 0 ? '' : 'null_data_style'" v-loading="tableLoading">
                            <template v-if="tableData.length > 0">
                                <li v-for="item in tableData" :key="item.id" :class="{ active: checkedRouteId == item.tripUuid }" @click="handleRow(item)">
                                    <div v-show="item.abnormalType" class="abnormal_li"><span>数据优化中</span></div>
                                    <div class="date">
                                        <b>{{ item.startTime.split(' ')[0] }}</b>
                                        <p>{{ item.startTime.split(' ')[1] + ' - ' + item.endTime.split(' ')[1] }}</p>
                                    </div>
                                    <div class="start_addr flex">
                                        <span>{{ item.startAddress }}</span>
                                    </div>
                                    <div class="end_addr flex">
                                        <span>{{ item.endAddress }}</span>
                                    </div>
                                </li>
                            </template>
                            <p v-else-if="!tableLoading" class="null_data_txt null_auto">暂无数据</p>
                        </ul>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import { BmlMarkerClusterer } from 'vue-baidu-map'
import AMapLoader from '@amap/amap-jsapi-loader'
var _this = ''
var outlierIconSize = { width: 40, height: 40 } //三急一超icon大小
var outlierIconAnchor = { width: 20, height: 40 } //三急一超偏离大小
export default {
    name: 'Home',
    components: {
        BmlMarkerClusterer
    },
    data() {
        return {
            AMapCircleRadio: '关闭编辑电子围栏',
            checkedMap: '百度',
            circleEditorAmap: '', //编辑高德地图电子围栏
            GDmapCircleVisible: false, //高德地图电子围栏显示与否
            GDmapCircle: '', //高德地图电子围栏
            circlePath: {
                center: {
                    lng: 116.404,
                    lat: 39.915
                },
                radius: 1000,
                editing: false
            }, //百度地图电子围栏
            center: { lng: 109.45744048529967, lat: 36.49771311230842 },
            zoom: 13,
            progressWidth: 80,
            radio: '不纠偏',
            BDmap: '',
            pathParam: [], //轨迹
            allData: {}, //所有数据
            fullscreenLoading: false,
            tableData: [],
            myChart: '',
            checkedRoute: {},
            checkedRouteId: '',
            checkedDeviceNumber: '',
            routeId: '',
            nowStatistics: {},
            polyline: '',
            markerArr: [], //点集合
            startMarker: '',
            endMarker: '',
            pages: 2,
            navForm: {
                //储存搜索框的检索条件信息
                condition: {
                    deviceNumber: ''
                },
                pageNo: 1,
                pageSize: 20
            },
            tripUuid: '',
            BMap: '',
            GDmap: '',
            startIcon: '',
            startPoint: '',
            endIcon: '',
            endPoint: '',
            speedUpIcon: {
                url: require('../../../public/images/puls_icon.png'),
                size: outlierIconSize,
                opts: {
                    anchor: outlierIconAnchor
                }
            },
            speedUpPoint: [],
            speedDownIcon: {
                url: require('../../../public/images/cut_icon.png'),
                size: outlierIconSize,
                opts: {
                    anchor: outlierIconAnchor
                }
            },
            speedDownPoint: [],
            turnIcon: {
                url: require('../../../public/images/swerve_icon.png'),
                size: outlierIconSize,
                opts: {
                    anchor: outlierIconAnchor
                }
            },
            turnPoint: [],
            upStyles: [
                {
                    url: require('../../../public/images/msi/aggregation_up.png'),
                    size: { width: 40, height: 40 },
                    textColor: '#fff',
                    opt_textSize: 12
                }
            ],
            downStyles: [
                {
                    url: require('../../../public/images/msi/aggregation_down.png'),
                    size: { width: 40, height: 40 },
                    textColor: '#fff',
                    opt_textSize: 12
                }
            ],
            turnStyles: [
                {
                    url: require('../../../public/images/msi/aggregation_turn.png'),
                    size: { width: 40, height: 40 },
                    textColor: '#fff',
                    opt_textSize: 12
                }
            ],
            polylinePath: [],
            isTrigger: true,
            tableLoading: true,
            mapLoading: true,
            clickUuidFlag: false
        }
    },
    methods: {
        handler({ BMap, map }) {
            this.BMap = BMap
            this.BDmap = map
        },
        format(percentage) {
            return percentage + '分'
        },
        //行程列表Scroll事件
        routeScroll(e) {
            if (e.srcElement.scrollTop + e.srcElement.offsetHeight >= e.srcElement.scrollHeight - 2) {
                if (this.isTrigger) {
                    this.isTrigger = false
                    if (this.pages > this.navForm.pageNo) {
                        this.navForm.pageNo++
                        this.getTbodyData('scroll')
                    }
                    setTimeout(() => {
                        this.isTrigger = true
                    }, 500)
                }
            }
        },
        getData() {
            this.navForm.condition.deviceNumber = this.getQueryString('deviceNumber')
            this.tripUuid = this.getQueryString('tripUuid')
            this.getTbodyData()
            this.getStatistics()
        },
        //获取今日合计
        getStatistics() {
            this.instance.get('/ubi/api/trip/todaySummary?deviceNumber=' + this.navForm.condition.deviceNumber).then((res) => {
                let data = res.data
                if (data.code == 200) {
                    data.data.totalMileage = data.data.totalMileage ? data.data.totalMileage.toFixed(2) : data.data.totalMileage
                    data.data.totalDuration = data.data.totalDuration ? data.data.totalDuration.toFixed(2) : data.data.totalDuration
                    this.nowStatistics = data.data
                }
            })
        },
        //获取行程列表
        getTbodyData(sign) {
            var _this = this
            this.instance
                .post('/ubi/api/trip/page', _this.navForm)
                .then((res) => {
                    this.tableLoading = false
                    if (res.data.code == 200) {
                        this.pages = res.data.data.pages
                        _this.tableData = [..._this.tableData, ...res.data.data.list]
                        _this.tableData.forEach((ele) => {
                            ele.endTimeNum = this.dateStrChangeTimeTamp(ele.endTime)
                        })
                        _this.tableData.sort((a, b) => {
                            return b.endTimeNum - a.endTimeNum
                        })

                        _this.startTime = this.$route.query.rowStartTime
                        if (sign != 'scroll') {
                            if (this.navForm.routeId) {
                                this.checkedRouteId = this.tableData.tripUuid
                                this.getRouteInfo()
                            } else {
                                var isFind = false
                                _this.tableData.forEach((val, key) => {
                                    if (_this.startTime == val.startTime) {
                                        _this.checkedRouteId = val.tripUuid
                                        _this.tripUuid = val.tripUuid
                                        _this.checkedDeviceNumber = val.deviceNumber
                                        isFind = true
                                    }
                                })
                                if (!isFind && !this.$route.query.tripUuid) {
                                    _this.checkedRouteId = this.tableData[0].tripUuid
                                    _this.checkedDeviceNumber = _this.tableData[0].deviceNumber
                                }
                                this.getRouteInfo(this.$route.query.tripUuid)
                            }
                        } else {
                            var isFind = false
                            _this.tableData.forEach((val, key) => {
                                if (_this.startTime == val.startTime) {
                                    _this.checkedRouteId = val.tripUuid
                                    _this.tripUuid = val.tripUuid
                                    _this.checkedDeviceNumber = val.deviceNumber
                                    isFind = true
                                }
                            })
                            if (!isFind) {
                                _this.checkedDeviceNumber = _this.tableData[0].deviceNumber
                            }
                            this.getRouteInfo(this.$route.query.tripUuid)
                        }
                    }
                    if (_this.tableData.length == 0) {
                        this.mapLoading = false
                    }
                })
                .catch((error) => {
                    this.mapLoading = false
                    this.$message.error('暂无数据')
                })
        },
        //获取行程详情
        getRouteInfo(tripUuid) {
            // const loading = this.$loading({
            //     lock: true,
            //     text: '加载中，请稍后...',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)'
            // })
            this.instance.get(`/ubi/api/trip/detail/${tripUuid ? tripUuid : this.checkedRouteId}`).then((res) => {
                // loading.close()
                this.clickUuidFlag = false
                let data = res.data
                if (data.code == 200) {
                    this.checkedRoute = this.handlerRowToFixed(data.data)
                    if (this.$route.query.tripUuid && !this.clickUuidFlag) {
                        this.checkedRouteId = this.$route.query.tripUuid
                        this.checkedDeviceNumber = this.$route.query.deviceNumber
                        this.initGPSData(this.$route.query.tripUuid)
                    } else {
                        this.initGPSData(data.data.tripUuid)
                    }
                }
            })
        },
        //处理GPS数据
        initGPSData(uuid) {
            this.mapLoading = true
            this.AMapCircleRadio = '关闭编辑电子围栏'
            let gpsData = []
            let gpsMap
            if (!uuid) return
            this.instance
                .post('/ubi/api/trajectory/tracking/gps', [uuid])
                .then((res) => {
                    this.mapLoading = false
                    let data = res.data
                    if (data.code === 200) {
                        gpsMap = data.data
                        let mapKeyNum = Object.keys(gpsMap)
                        // 合并gps行程并按照时间顺序排序
                        let tmpGpsMapArr = []
                        for (const key in gpsMap) {
                            if (gpsMap[key] && key != 'accidentGPS') {
                                gpsMap[key].forEach((item) => {
                                    tmpGpsMapArr.push(item)
                                })
                            }
                        }
                        tmpGpsMapArr.sort((a, b) => {
                            return new Date(a.t).getTime() - new Date(b.t).getTime()
                        })
                        gpsData = tmpGpsMapArr

                        if (gpsData.length > 0) {
                            switch (this.checkedMap) {
                                case '高德':
                                    gpsData.forEach((val, key) => {
                                        let obj = this.BMaptoAMap(val.lon, val.lat)
                                        val.lng = obj.lng
                                        val.lat = obj.lat
                                    })
                                    this.loadAmap(gpsData)

                                    break
                                case '百度':
                                    this.setLineMarker(gpsData)
                                    this.setBMapCircle(gpsData)
                                    break

                                default:
                                    break
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.mapLoading = false
                    this.$message.error('接口异常，请稍后重试！-0')
                })
        },
        //描绘百度地图的电子围栏
        setBMapCircle(gpsData) {
            // this.zoom = 11
            this.circlePath.center = { lng: gpsData[0].lon, lat: gpsData[0].lat }
        },
        //百度地图圆形覆盖物的属性发生变化时触发此事件
        updateCirclePath(e) {
            // this.circlePath.center = e.target.getCenter()
            // this.circlePath.radius = e.target.getRadius()
        },
        //点击行程列表
        handleRow(item) {
            if (this.checkedRouteId != item.id) {
                this.clickUuidFlag = true
                this.checkedRouteId = item.tripUuid
                this.checkedDeviceNumber = item.deviceNumber
                this.polylinePath = []
                // 当前行程
                this.checkedRoute = this.handlerRowToFixed(item)
                // gps轨迹
                this.initGPSData(this.checkedRouteId)
            }
        },
        // 处理row数据，特定字段保留2位小数
        handlerRowToFixed(data) {
            data.mileage = String(data.mileage).indexOf('.') > -1 ? Number(data.mileage).toFixed(2) : data.mileage
            data.drivingDurationMinutes = String(data.drivingDurationMinutes).indexOf('.') > -1 ? Number(data.drivingDurationMinutes).toFixed(2) : data.drivingDurationMinutes
            data.avgSpeedPerHour = String(data.avgSpeedPerHour).indexOf('.') > -1 ? Number(data.avgSpeedPerHour).toFixed(2) : data.avgSpeedPerHour
            data.maxSpeed = String(data.maxSpeed).indexOf('.') > -1 ? Number(data.maxSpeed).toFixed(2) : data.maxSpeed
            data.commercialVehicleScore = String(data.commercialVehicleScore).indexOf('.') > -1 ? Number(data.commercialVehicleScore.toFixed(2)) : data.commercialVehicleScore
            data.drivingDurationHours = String(data.drivingDurationHours).indexOf('.') > -1 ? Number(data.drivingDurationHours).toFixed(2) : data.drivingDurationHours
            return data
        },
        //加载高德地图
        loadAmap(gpsData) {
            AMapLoader.load({
                key: 'dabcacd9e784df189b7f69e994d4793e', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.Scale', 'AMap.CircleEditor'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    this.GDmap = new AMap.Map('container', {
                        mapStyle: 'amap://styles/light', //设置地图的显示样式
                        zoom: 11, //级别
                        zooms: [5, 16],
                        resizeEnable: true,
                        zoomEnable: true,
                        center: [gpsData[0].lng, gpsData[0].lat] //中心点坐标
                        // viewMode:'3D'//使用3D视图
                    })
                    //增加比例尺控件
                    var scale = new AMap.Scale({
                        position: 'RT'
                    })
                    this.GDmap.addControl(scale)
                    //描绘高德地图的点和线
                    this.setAMapMarkerLine(gpsData)
                    //描绘高德地图的电子围栏
                    this.GDmapCircleVisible && this.setAMapCircle(gpsData)
                })
                .catch((e) => {
                    console.log(e)
                })
        },
        //描绘高德地图的电子围栏
        setAMapCircle(gpsData) {
            var myLngLat = new AMap.LngLat(gpsData[0].lng, gpsData[0].lat)
            this.GDmapCircle = new AMap.Circle({
                center: myLngLat, // 圆心位置
                radius: 1000, //半径
                strokeColor: '#F33', //线颜色
                strokeOpacity: 1, //线透明度
                strokeWeight: 3, //线粗细度
                fillColor: '#ee2200', //填充颜色
                fillOpacity: 0.35 //填充透明度
            })
            this.GDmap.add(this.GDmapCircle)
            this.circleEditorAmap = new AMap.CircleEditor(this.GDmap, this.GDmapCircle)
        },
        //打开编辑高德地图电子围栏
        openEditCircle() {
            if (this.checkedMap == '百度') {
                this.circlePath.editing = true
            } else if (this.checkedMap == '高德') {
                this.circleEditorAmap.open()
            }
        },
        //关闭编辑高德地图电子围栏
        closeEditCircle() {
            if (this.checkedMap == '百度') {
                this.circlePath.editing = false
            } else if (this.checkedMap == '高德') {
                this.circleEditorAmap.close()
            }
        },
        //描绘高德地图的点和线
        setAMapMarkerLine(gpsData) {
            this.polyline && this.GDmap.remove(this.polyline)
            this.markerArr.length > 0 &&
                this.markerArr.forEach((val, key) => {
                    this.GDmap.remove(val)
                })
            var _len = gpsData.length
            var path = []
            gpsData.forEach((val, key) => {
                path.push(new AMap.LngLat(val.lng, val.lat))
            })
            // this.GDmap.setZoomAndCenter(11, [gpsData[0].lng, gpsData[0].lat]);
            const startIcon = new AMap.Icon({
                size: new AMap.Size(38, 49), // 图标尺寸
                image: require('../../../public/images/depicon.png'),
                imageSize: new AMap.Size(38, 49) // 根据所设置的大小拉伸或压缩图片
            })
            const endIcon = new AMap.Icon({
                size: new AMap.Size(38, 49), // 图标尺寸
                image: require('../../../public/images/desicon.png'),
                imageSize: new AMap.Size(38, 49) // 根据所设置的大小拉伸或压缩图片
            })
            const pulsIcon = new AMap.Icon({
                size: new AMap.Size(40, 40), // 图标尺寸
                image: require('../../../public/images/puls_icon.png'),
                imageSize: new AMap.Size(40, 40) // 根据所设置的大小拉伸或压缩图片
            })
            const cutIcon = new AMap.Icon({
                size: new AMap.Size(40, 40), // 图标尺寸
                image: require('../../../public/images/cut_icon.png'),
                imageSize: new AMap.Size(40, 40) // 根据所设置的大小拉伸或压缩图片
            })
            //急转弯
            const swerveIcon = new AMap.Icon({
                size: new AMap.Size(40, 40), // 图标尺寸
                image: require('../../../public/images/swerve_icon.png'),
                imageSize: new AMap.Size(40, 40) // 根据所设置的大小拉伸或压缩图片
            })
            this.startMarker = new AMap.Marker({
                position: new AMap.LngLat(gpsData[0].lng, gpsData[0].lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                offset: new AMap.Pixel(-19, -49),
                icon: startIcon
            })
            this.endMarker = new AMap.Marker({
                position: new AMap.LngLat(gpsData[_len - 1].lng, gpsData[_len - 1].lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                offset: new AMap.Pixel(-19, -49),
                icon: endIcon
            })
            this.markerArr = [this.startMarker, this.endMarker] //点标记组
            var checkedRoute = this.checkedRoute //选中的行程详情
            // //急加速点
            // if(checkedRoute.sharpSpeedUp>0){
            // 	checkedRoute.sharpSpeedUpPoints.forEach((val,key)=>{
            // 		let obj=this.BMaptoAMap(val.lon,val.lat);
            // 		val.lng=obj.lng;
            // 		val.lat=obj.lat;
            // 		let _marker = new AMap.Marker({
            // 			position: new AMap.LngLat(val.lng, val.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            // 			offset: new AMap.Pixel(-20, -40),
            // 			icon: pulsIcon,
            // 		});
            // 		// this.markerArr.push(_marker);
            // 	})
            // }
            // //急减速点
            // if(checkedRoute.sharpSpeedDown>0){
            // 	checkedRoute.sharpSpeedDownPoints.forEach((val,key)=>{
            // 		let obj=this.BMaptoAMap(val.lon,val.lat);
            // 		val.lng=obj.lng;
            // 		val.lat=obj.lat;
            // 		let _marker = new AMap.Marker({
            // 			position: new AMap.LngLat(val.lng, val.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            // 			offset: new AMap.Pixel(-20, -40),
            // 			icon: cutIcon,
            // 		});
            // 		// this.markerArr.push(_marker);
            // 	})
            // }
            // //急转弯
            // if(checkedRoute.sharpTurn>0){
            // 	checkedRoute.sharpTurnPoints.forEach((val,key)=>{
            // 		let obj=this.BMaptoAMap(val.lon,val.lat);
            // 		val.lng=obj.lng;
            // 		val.lat=obj.lat;
            // 		let _marker = new AMap.Marker({
            // 			position: new AMap.LngLat(val.lng, val.lat),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            // 			offset: new AMap.Pixel(-20, -40),
            // 			icon: swerveIcon,
            // 		});
            // 		// this.markerArr.push(_marker);
            // 	})
            // }
            this.GDmap.add(this.markerArr)
            // 创建折线实例
            this.polyline = new AMap.Polyline({
                path: path,
                strokeWeight: 5,
                //isOutline: true,	// 是否描边
                //borderWeight: 2, // 描边宽度，默认为 1
                //outlineColor: 'red', // 描边颜色
                lineCap: 'round',
                strokeColor: '#0766FE', // 线条颜色
                lineJoin: 'round', // 折线拐点连接处样式
                showDir: true, //路径方向箭头
                strokeOpacity: 1 //线透明度
            })
            // 将折线添加至地图实例
            this.GDmap.add(this.polyline)
            this.GDmap.setFitView(this.polyline)
        },
        //百度地图描绘地图的点和线
        setLineMarker(gpsData) {
            var checkedRoute = this.checkedRoute
            var _len = gpsData.length
            var path = []
            gpsData.forEach((val, key) => {
                path.push({
                    lng: val.lon,
                    lat: val.lat
                })
            })
            this.polylinePath = path
            this.center.lng = gpsData[0].lon
            this.center.lat = gpsData[0].lat
            let iconSize = {
                width: 38,
                height: 49
            }
            this.startIcon = {
                url: require('../../../public/images/depicon.png'),
                size: iconSize,
                opts: {
                    // imageSize: iconSize,
                    anchor: {
                        width: 19,
                        height: 49
                    }
                }
            }
            this.endIcon = {
                url: require('../../../public/images/desicon.png'),
                size: iconSize,
                opts: {
                    imageSize: iconSize,
                    anchor: {
                        width: 19,
                        height: 49
                    }
                }
            }
            this.startPoint = {
                lng: gpsData[0].lon,
                lat: gpsData[0].lat
            }
            this.endPoint = {
                lng: gpsData[_len - 1].lon,
                lat: gpsData[_len - 1].lat
            }
            // //急加速点处理
            // var sharpSpeedUpPoints=checkedRoute.sharpSpeedUpPoints;
            // this.speedUpPoint=[];
            // if(sharpSpeedUpPoints.length>0){
            // 	sharpSpeedUpPoints.forEach((val,key)=>{
            // 		this.speedUpPoint.push({
            // 		   lng:val.lon,
            // 		   lat:val.lat
            // 	   })
            // 	})
            // }
            // //急减速点处理
            // var sharpSpeedDownPoints=checkedRoute.sharpSpeedDownPoints;
            // this.speedDownPoint=[];
            // if(sharpSpeedDownPoints.length>0){
            // 	sharpSpeedDownPoints.forEach((val,key)=>{
            // 		this.speedDownPoint.push({
            // 		   lng:val.lon,
            // 		   lat:val.lat
            // 		})
            // 	})
            // }
            // //急转弯点处理
            // var sharpTurnPoints=checkedRoute.sharpTurnPoints;
            // this.turnPoint=[];
            // if(sharpTurnPoints.length>0){
            // 	sharpTurnPoints.forEach((val,key)=>{
            // 		this.turnPoint.push({
            // 		   lng:val.lon,
            // 		   lat:val.lat
            // 		})
            // 	})
            // }
            setTimeout(() => {
                this.BDmap.setViewport(path)
            }, 100)
        },
        // 时间字符串转时间戳
        dateStrChangeTimeTamp(dateStr) {
            dateStr = dateStr.substring(0, 19)
            dateStr = dateStr.replace(/-/g, '/')
            return new Date(dateStr).getTime()
        }
    },
    mounted() {
        _this = this
        this.progressWidth = document.querySelector('.vehicle_info2').getBoundingClientRect().width * 0.55
        window.addEventListener('resize', () => {
            _this.progressWidth = document.querySelector('.vehicle_info2').getBoundingClientRect().width * 0.55
        })

        this.getData()
    }
}
</script>
<style scoped>
.select_map {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    z-index: 1000;
}
.abnormal_li {
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    text-align: right;
    color: #e4393c;
    padding: 0.1rem 0.15rem 0 0;
}
.abnormal_li span {
    display: inline-block;
    width: 12px;
    height: 100%;
    font-size: 12px;
}
.map_radio {
    position: absolute;
    top: 0.1rem;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, 0);
}
.vehicle_info3 ul,
.vehicle_info4 ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    margin-top: 0.12rem;
    padding: 0 0.2rem;
}
.vehicle_info4 ul {
    padding: 0 0.32rem;
}
.vehicle_info4 ul span {
    color: #767676;
    font-size: 0.12rem;
    display: inline-block;
    margin-left: 0.03rem;
}
.vehicle_info4 ul p {
    margin-top: 0.12rem;
}
.vehicle_info3 li p,
.vehicle_info4 ul b {
    font-size: 0.2rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    margin-bottom: 0.1rem;
}
.vehicle_info1 .flex {
    line-height: 1.375;
    padding: 0 0.16rem;
    margin-top: 0.15rem;
    justify-content: flex-start;
}
.icon {
    width: 0.24rem;
}
.vehicle_info1 .icon {
    width: 0.22rem;
    margin-right: 0.1rem;
}
.vehicle_info3 .icon,
.vehicle_info4 .icon {
    margin: 0 auto 0.05rem;
}
.info_title {
    width: 100%;
    height: 0.46rem;
    /* line-height: 0.46rem; */
    /* font-size: 0.18rem; */
    border-bottom: 1px dashed #e7e7e7;
    margin-left: 0.16rem;
    display: flex;
    align-items: center;
    font-size: 0.18rem;
}
.info_title::before {
    display: block;
    content: '';
    width: 0.06rem;
    height: 0.18rem;
    background: #051932;
    border-radius: 0.03rem;
    margin-right: 0.08rem;
}
.vehicle_info_box {
    /* position: absolute;
		left:0.2rem;
		bottom:0.2rem;
		right:4rem ;
		z-index: 50; */
    height: 20%;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0 0.2rem 0.2rem;
}
.vehicle_info {
    width: 22%;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
}
.vehicle_info2 {
    width: 10.33%;
}
.vehicle_info3 {
    width: 29%;
}
.vehicle_info4 {
    width: 34.67%;
}
.progress_box {
    text-align: center;
    padding-top: 0.1rem;
    /* width: 80%; */
}
/deep/.el-progress__text {
    font-size: 0.18rem !important;
}
.route_list li {
    padding: 0.16rem 0.16rem 0.08rem;
    border-top: 1px dashed #e7e7e7;
    cursor: pointer;
    margin-bottom: 1px;
    position: relative;
}
.route_list li:hover,
.route_list .active {
    background: rgba(0, 148, 173, 0.07);
    /* border: 1px solid #09AAC6; */
    box-shadow: 0 0 0 1px #09aac6;
}

.start_addr,
.end_addr {
    color: #051932;
    font-weight: 400;
    justify-content: flex-start;
    width: 100%;
    line-height: 1.8;
}
.start_addr::before,
.end_addr::before {
    display: block;
    content: '';
    width: 0.14rem;
    height: 0.18rem;
    background: url(../../../public/images/msi/vehicle_map/qidian.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 0.1rem;
}
.end_addr::before {
    background: url(../../../public/images/msi/vehicle_map/zhongdian.png) no-repeat;
    background-size: 100% 100%;
}
.start_addr span,
.end_addr span {
    width: 3.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.date b {
    font-family: PingFangSC-Heavy, PingFang SC;
    font-weight: 800;
    margin-right: 0.16rem;
    display: inline-block;
    margin-bottom: 0.05rem;
}
.date span {
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #828893;
    font-size: 0.14rem;
}
.route_title {
    height: 0.4rem;
    font-size: 0.2rem;
    margin-left: 0.16rem;
    display: flex;
}
.route_title::before {
    display: block;
    content: '';
    width: 0.06rem;
    height: 0.18rem;
    background: #051932;
    border-radius: 0.03rem;
    margin-right: 0.08rem;
}
.route_list_box {
    width: 100%;
    height: 98%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0.2rem 0.15rem 0;
}
.now_route {
    /* width: 3.4rem; */
    margin-top: 0.15rem;
    background-color: #fff;
    border-radius: 4px;
    padding: 0.2rem;
    /* background: rgba(0, 148, 173, 0.7); */
    /* box-shadow: 0 0 0 1px #09AAC6; */
}
/* .now_route::after{
		display: block;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 100;
		top:0;
		left:0;
		background: rgba(0, 148, 173, 0.07);
	} */
.now_route .route_title {
    margin: 0;
}
.total {
    /* width: 3.6rem; */
    width: 100%;
    height: 0.48rem;
    background: #ffffff;
    border-radius: 4px;
    padding: 0.16rem;
}
.total .flex {
    align-items: baseline;
    width: 48%;
}
.total .flex div {
    width: 0.66rem;
    min-width: 48px;
}
.total .flex b {
    font-size: 0.2rem;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    margin: 0 0.04rem 0 0.2rem;
    color: #09a9c5;
}
.total .flex span {
    color: #09a9c5;
}
.route_list {
    /* position: absolute;
		top:2.4rem;
		right:0;
		bottom:0;
		z-index: 60;
		width: 3.6rem; */
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 0.16rem 1px;
    margin-top: 0.15rem;
    /* overflow: auto; */
}
.route_list ul {
    /* overflow: auto; */
    /* position: absolute;
		top: 0.5rem;
		left: 0;
		bottom: 0;
		z-index: 80; */
    /* padding: 1px; */
}
.null_data_style {
    height: 700px;
}
.wrap {
    background: #f0f2f5;
    height: 100%;
    position: relative;
    color: #000;
}
#container {
    /* position: absolute;
		top:0;
		bottom:1.8rem;
		left: 0;
		right: 4rem;
		z-index: 10; */
    width: 100%;
    height: 80%;
}
.el-row,
.el-col {
    height: 100%;
}
.null_data_txt {
    text-align: center;
}
.null_auto {
    position: relative;
    top: 35%;
}
</style>